<template>
  <Dropdown
      class="language-dropdown"
      :items="languages"
      :display-key="'name'"
      :value-key="'value'"
      :selected="selectedLan"
      @itemSelected="changeLocale"
  />
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import Dropdown from "@/components/common/Dropdown";

export default {
  name: "LanguageDropDown",
  components: {
    Dropdown
  },
  props: [],
  data() {
    return {
      languages: [
        {name: 'EN', value: 'en'},
        {name: 'ET', value: 'et'}
      ],
      selectedLan: null,
    };
  },
  created() {
    this.selectedLan = this.languages.find(lan => lan.value === this.language);
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["language"]),
  },
  watch: {
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageSet"]),
    changeLocale(loc) {
      this.selectedLan = loc
      this.setLanguage(loc.value);
      this.setLanguageSet(true);
      this.$i18n.locale = loc.value;
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.language-dropdown {
  background: white;
  border-radius: 8px;
}

::v-deep .dropbtn {
  min-width: auto;
  border: none;
}

::v-deep .dropdown-single {
  min-width: auto;
}
</style>
