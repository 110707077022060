var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Dropdown', {
    staticClass: "language-dropdown",
    attrs: {
      "items": _vm.languages,
      "display-key": 'name',
      "value-key": 'value',
      "selected": _vm.selectedLan
    },
    on: {
      "itemSelected": _vm.changeLocale
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }